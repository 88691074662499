import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/molecules/seo'
import Hero from '../components/organisms/hero'
import { getLocalNodes } from '../helpers/locale'
import { useResourceTextData } from '../hooks/useResourceTextData'
import { ContentfulHeroPanelConnection } from '../types/graphql-types'

type PageNotFoundTypes = {
  data: {
    allContentfulHeroPanel: ContentfulHeroPanelConnection
  }
}

const PageNotFound = ({ data }: PageNotFoundTypes): JSX.Element => {
  const heroData = getLocalNodes(data.allContentfulHeroPanel.nodes)
  return (
    <Layout>
      <Seo title="404 Page not found" />
      <Hero
        title={useResourceTextData(
          '404.title',
          "Oops, this doesn't look gooood..."
        )}
        subTitle={useResourceTextData('404.subTitle', '404 - Page not found')}
        body={{
          body: useResourceTextData(
            '404.body',
            "You might have taken a wrong turn - we can't find the page you're looking for! Either something's broken, been moved or mistyped. Maybe start again from the homepage, or contact us if you keep landing here."
          ),
        }}
        cta={[
          {
            id: 'link',
            title: useResourceTextData('404.cta', 'Go to the homepage'),
            url: '/',
            internal: { type: 'ContentfulNavigationItem' },
          },
        ]}
        image={heroData[0].image.fluid}
      />
      {/*<h1>404</h1>*/}
    </Layout>
  )
}

export const pageQuery = graphql`
  query FourZeroFourQuery {
    allContentfulHeroPanel(filter: { key: { eq: "home.hero" } }) {
      nodes {
        id
        title
        image {
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        body {
          body
        }
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        node_locale
      }
    }
  }
`

export default PageNotFound
